import React, { useMemo } from "react";
import { ParsedUrlQueryInput } from "querystring";
import { Anchor } from "./Anchor";
import { SystemLink } from "./SystemLink";
import { Info } from "./Info";
import { DockGroupType, VehicleInventoryMetric } from "../src/types";
import { IconName } from "./Icon";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { Box, Link } from "@material-ui/core";
import { getMapLocationUrl } from "../src/utils/maps";

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);

export function VehiclesMetricLink({
  vehicleInventoryMetric = "vehiclesUnavailableInStation",
  ...props
}: {
  decoration?: string;
  mute?: boolean;
  small?: boolean;
  vehicleInventoryMetric?: VehicleInventoryMetric;
  children: React.ReactNode;
}) {
  return (
    <SystemLink
      href={{
        pathname: "/[systemId]/vehicles",
        query: {
          tabId: "list",
          vehicleInventoryMetric
        }
      }}
      passHref
    >
      <Anchor
        onClick={event => {
          event.stopPropagation();
        }}
        {...props}
      />
    </SystemLink>
  );
}

export function VehicleLink({ id, name }: { id: string; name: string | null }) {
  return (
    <SystemLink
      href={{
        pathname: "/[systemId]/vehicles/[vehicleId]",
        query: { vehicleId: id }
      }}
      passHref
    >
      <Info.Anchor
        icon="vehicle"
        onClick={event => {
          event.stopPropagation();
        }}
      >
        {name || id}
      </Info.Anchor>
    </SystemLink>
  );
}

export function ControllerLink({
  id,
  name,
  query,
  prefetch = false
}: {
  id: string;
  name?: string;
  query?: ParsedUrlQueryInput;
  prefetch?: boolean;
}) {
  return (
    <SystemLink
      href={{
        pathname: "/[systemId]/controllers/[controllerId]",
        query: { controllerId: id, ...query }
      }}
      passHref
      prefetch={prefetch}
    >
      <Info.Anchor
        icon="signal"
        onClick={event => {
          event.stopPropagation();
        }}
      >
        {name || id}
      </Info.Anchor>
    </SystemLink>
  );
}

interface DockGroupLinkProps {
  dockGroup:
    | null
    | undefined
    | {
        id: string;
        title: string | null | undefined;
        type?: string | null | undefined;
      };

  query?: ParsedUrlQueryInput;
  prefetch?: boolean;
  location?:
    | null
    | undefined
    | {
        location: {
          lat: number;
          lng: number;
        };
        address: string | null | undefined;
      };
  dockNumber?: number | null | undefined;
  type?: DockGroupType | null;
}

export function DockGroupLink({
  dockGroup,
  location,
  query,
  dockNumber,
  prefetch = false
}: DockGroupLinkProps) {
  const icons = useMemo<IconName[]>(() => {
    const result: IconName[] = [];

    if (dockGroup) {
      switch (dockGroup.type) {
        case DockGroupType.hybrid:
          result.push("station", "virtualStation");
          break;
        case DockGroupType.physical:
          result.push("station");
          break;
        case DockGroupType.virtual:
          result.push("virtualStation");
          break;
        default:
          result.push("station");
          break;
      }
    }

    if (location) {
      result.push("location");
    }

    return result;
  }, [dockGroup, location]);

  const coords = useMemo(
    () =>
      location?.location
        ? `${location.location.lat.toFixed(6)}, ${location.location.lng.toFixed(
            6
          )}`
        : undefined,
    [location]
  );

  const title = useMemo(
    () => (
      <>
        {dockGroup && (dockGroup?.title || dockGroup?.id)}
        {!dockGroup && location?.address && location.address}
        {!dockGroup && !location?.address && coords && <Box>{coords}</Box>}
        {dockNumber ? ` (${dockNumber})` : ""}
      </>
    ),
    [dockGroup, location, coords, dockNumber]
  );

  const tooltipTitle = useMemo(
    () => (
      <Box style={{ flexDirection: "column" }}>
        {dockGroup && <Box>{dockGroup?.title || dockGroup?.id}</Box>}
        {location?.address && <Box>{location.address}</Box>}
        {coords && <Box>{coords}</Box>}
      </Box>
    ),
    [coords, dockGroup, location]
  );

  const content = useMemo(
    () => (
      <HtmlTooltip title={tooltipTitle}>
        <Info.Anchor
          icon={icons}
          onClick={event => {
            event.stopPropagation();
          }}
        >
          {title}
        </Info.Anchor>
      </HtmlTooltip>
    ),
    [icons, title, tooltipTitle]
  );

  if (!dockGroup && !location) {
    return null;
  }

  return (
    <>
      {dockGroup ? (
        <SystemLink
          href={{
            pathname: "/[systemId]/stations/[stationId]",
            query: { stationId: dockGroup.id, ...query }
          }}
          prefetch={prefetch}
        >
          {content}
        </SystemLink>
      ) : (
        <Link
          href={getMapLocationUrl(
            location?.location.lat,
            location?.location.lng
          )}
          target="_blank"
          rel="noreferrer"
          style={{ borderBottom: "none" }}
        >
          {content}
        </Link>
      )}
    </>
  );
}

export function OperationLocationLink({
  id,
  title,
  query = {}
}: {
  id: string;
  title: string;
  query?: ParsedUrlQueryInput;
}) {
  return (
    <SystemLink
      href={{
        pathname: "/[systemId]/vehicles",
        query: {
          variables: JSON.stringify({
            orderBy: "updatedAt",
            orderDirection: "desc",
            offset: 0,
            limit: 50,
            name: "",
            rfid: "",
            broken: "",
            freeFloating: "",
            number: "",
            entityTags: "",
            damageType: "",
            frameNumber: "",
            controllerState: "",
            vehicleCategory: "",
            availabilityStates: "",
            controllerPowerState: "",
            batteryChargeLessThan: "",
            availabilityStateReasons: "",
            batteryChargeGreaterThan: "",
            operationLocations: id
          }),
          ...query
        }
      }}
    >
      <Info.Anchor
        icon="location"
        onClick={event => {
          event.stopPropagation();
        }}
      >
        {title || id}
      </Info.Anchor>
    </SystemLink>
  );
}

export function UserLink({
  id,
  query,
  variant,
  prefetch = false,
  ...props
}: {
  id: string;
  query?: ParsedUrlQueryInput;
  variant?: "simple";
  prefetch?: boolean;
}) {
  return (
    <SystemLink
      href={{
        pathname: "/[systemId]/users/[userId]",
        query: { userId: id, ...query }
      }}
      prefetch={prefetch}
      {...props}
    >
      {variant && variant === "simple" ? (
        <Anchor
          onClick={event => {
            event.stopPropagation();
          }}
        >
          {id}
        </Anchor>
      ) : (
        <Info.Anchor
          icon="user"
          onClick={event => {
            event.stopPropagation();
          }}
        >
          {id}
        </Info.Anchor>
      )}
    </SystemLink>
  );
}

export function TripLink({
  id,
  query,
  children,
  prefetch
}: {
  id: string;
  query?: ParsedUrlQueryInput;
  prefetch?: boolean;
  children?: React.ReactNode;
}) {
  return (
    <SystemLink
      href={{
        pathname: "/[systemId]/trips/[tripId]",
        query: { tripId: id, ...query }
      }}
      passHref
      prefetch={prefetch}
    >
      {children ? (
        <Anchor
          onClick={event => {
            event.stopPropagation();
          }}
        >
          {children}
        </Anchor>
      ) : (
        <Info.Anchor
          onClick={event => {
            event.stopPropagation();
          }}
          icon="plan"
        >
          {id}
        </Info.Anchor>
      )}
    </SystemLink>
  );
}

export function CompanyLink({
  id,
  name,
  query,
  children,
  prefetch
}: {
  id: string;
  name: string;
  query?: ParsedUrlQueryInput;
  prefetch?: boolean;
  children?: React.ReactNode;
}) {
  return (
    <SystemLink
      href={{
        pathname: "/[systemId]/business/[companyId]",
        query: { companyId: id, ...query }
      }}
      passHref
      prefetch={prefetch}
    >
      {children ? (
        <Anchor
          onClick={event => {
            event.stopPropagation();
          }}
        >
          {children}
        </Anchor>
      ) : (
        <Info.Anchor
          onClick={event => {
            event.stopPropagation();
          }}
          icon="plan"
        >
          {name}
        </Info.Anchor>
      )}
    </SystemLink>
  );
}

export function ProductLink({
  id,
  query,
  children,
  prefetch
}: {
  id: string;
  query?: ParsedUrlQueryInput;
  prefetch?: boolean;
  children?: React.ReactNode;
}) {
  return (
    <SystemLink
      href={{
        pathname: "/[systemId]/sales/products/[productId]",
        query: { productId: id, ...query }
      }}
      passHref
      prefetch={prefetch}
    >
      {children ? (
        <Anchor
          onClick={event => {
            event.stopPropagation();
          }}
        >
          {children}
        </Anchor>
      ) : (
        <Info.Anchor
          onClick={event => {
            event.stopPropagation();
          }}
          icon="plan"
        >
          {id}
        </Info.Anchor>
      )}
    </SystemLink>
  );
}
